import React from "react"
import PropTypes from 'prop-types'
import Page from "../components/Page"
import data from '../data/archiveItem'

const ArchivePage = ({ pageContext }) => {
  
  data.components[0].components[0].props.urls = pageContext.urls
  data.components[0].components[1].props = pageContext.archiveItem
  data.components[0].components[2].props.videos = pageContext.archiveItem.videos
  data.components[0].components[3].props.media = pageContext.archiveItem.media

  return (
    <>
      <Page
        name="archiveItem"
        language={pageContext.language}
        data={data}
      />
    </>
  )
}

ArchivePage.propTypes = {
  pageContext: PropTypes.shape({
    language: PropTypes.string.isRequired,
    urls: PropTypes.array.isRequired,
    archiveItem: PropTypes.shape().isRequired,
  }),
}

export default ArchivePage
