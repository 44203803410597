import menu from './menu'
import meta from './meta'
import footer from './footer'

const data = {
  menu,
  meta,
  components: [
    {
      name: 'Wrapper',
      components: [
        {
          name: 'Breadcrumb',
          props: {
            urls: [],
          }
        },
        {
          name: "Title",
          props: {
            title: {
              es: "Edición 2019",
              ca: "Edició 2019",
              en: "2019 Edition"
            },
            text: {
              es:
                `<ul>
                  <li>Anaïs Pérez Figueras. Directora de Comunicación, Google Iberia</li>
                  <li>Macarena Rey. CEO, Shine Iberia</li>
                  <li>Vincent Sourdeau. Director General, Disney Channel</li>
                  <li>Tomás Fdo. Flores. Director, Radio-3</li>
                  <li>Ricard Robles. Director, Sónar</li>
                  <li>Enric Jové. CEO Mccann Barcelona & CIO Mccann Spain</li>
                  <li>Oscar Romagosa. Productor Ejecutivo, Canada.</li>
                  <li>Ana Gea. Fundadora de Graffica.</li>
                  <li>Víctor Palau. Fundador de Graffica.</li>
                </ul>`,
              ca: 
                `<ul>
                  <li>Anaïs Pérez Figueras. Directora de Comunicació, Google Ibèria</li>
                  <li>Macarena Rei. CEO, Shine Ibèria</li>
                  <li>Vincent Sourdeau. Director General, Disney Channel</li>
                  <li>Tomás Fdo. Flores. Director, Radi-3</li>
                  <li>Ricard Robles. Director, Sónar</li>
                  <li>Enric Jové. CEO Mccann Barcelona & CIO Mccann Spain</li>
                  <li>Oscar Romagosa. Productor Executiu, Canada.</li>
                  <li>Ana Gea. Fundadora de Graffica.</li>
                  <li>Víctor Palau. Fundador de Graffica.</li>
                </ul>`,
              en:
                `<ul>
                  <li> Anaïs Pérez Figueras. Communication Director, Google Iberia</li>
                  <li> Macarena Rey. CEO, Shine Iberia</li>
                  <li> Vincent Sourdeau. CEO, Disney Channel</li>
                  <li> Tomás Fdo. Flores. Director, Radio-3</li>
                  <li> Ricard Robles. Director, Sónar </li>
                  <li> Enric Jové. CEO Mccann Barcelona & CIO Mccann Spain </li>
                  <li> Oscar Romagosa. Executive Producer, Canada. </li>
                  <li> Ana Gea. Founder of Graffica. </li>
                  <li> Víctor Palau. Founder of Graffica. </li>
                </ul>`,
            },
          }
        },
        {
          name: "Archive",
          props: {
            videos: [
              // {id: "1", size: "large", videoId: "06xJ1Dz1rA8"},
              // {id: "2", size: "small", videoId: "35AILvtJs-c"},
              // {id: "3", size: "medium", videoId: "CjdmW4TLknA"},
              // {id: "4", size: "large", videoId: "NrYnHg6vo-E"},
              // {id: "5", size: "medium", videoId: "CRloD6p04cY"},
              // {id: "6", size: "small", videoId: "9ZTZ49K6Bts"},
              // {id: "7", size: "large", videoId: "l0ufHnJjiLI"},
              // {id: "8", size: "small", videoId: "87Yt4Sfs7K0"},
              // {id: "9", size: "medium", videoId: "Eg-xP0Onjec"}
            ]
          }
        },
        {
          name: "Media",
          props: {
            title: {
              es: "Media",
              ca: "Media",
              en: "Media"
            },
            items: [
              {
                id: "1",
                url: "https://www.ccma.cat/audio/embed/1038352/",
                image: "/media/2019/2019_1.png",
                title: {
                  es: "Programa completo de Popap / Catalunya Radio (03/05/2019)",
                  ca: "Programa completo de Popap / Catalunya Radio (03/05/2019)",
                  en: "Programa completo de Popap / Catalunya Radio (03/05/2019)"
                },
              },
              {
                id: "2",
                url: "https://www.youtube.com/embed/UYihg-P9lDY",
                image: "/media/2019/2019_2.png",
                title: {
                  es: "IB3 TV / Formentera 2.0 - 2019",
                  ca: "IB3 TV / Formentera 2.0 - 2019",
                  en: "IB3 TV / Formentera 2.0 - 2019"
                },
              }
            ]
          }
        },
        footer
      ]
    }
  ],
}

export default data